.main-weekPlanning {
  @apply flex flex-row space-x-2;
  height: calc(100vh - 90px)
}

.weekPlanning-calendar {
  @apply w-4;
}

.weekPlanning-title-calendar {
  @apply text-center text-xs pt-3 font-semibold;
}

.weekPlanning-number-calendar {
  @apply grid h-full w-4;
}

.weekPlanningDayFullWeek-list {
  @apply grid grid-cols-7 mb-1 gap-x-1;
  background-color: #064c69
}

.weekPlanningDay5DayWeek-list {
  @apply grid grid-cols-5 mb-1 gap-x-1;
  background-color: #064c69
}

.weekPlanningDay-list {
  @apply grid grid-cols-7 mb-1 gap-x-1;
}