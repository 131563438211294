.schema-acticvity-title {
  min-width: 50%;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0.25rem;
}

.multiple-day-schema-acticvity-title {

  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.weekPlanningDate-box {
  @apply w-full text-xl;

}

.weekPlanningDate-box.presentationMode {

  height: calc(calc(100vh - 130px) / 5);
}